import React from 'react';


export default function ResetPassword() {
  return <div>
    Reset password
    <input type="text" placeholder="email"/>
    <button>reset</button>
  </div>;
}
